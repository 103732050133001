import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider, RequireAuth } from '../useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Home from './home';

// Lazy loading of webpages after the dashboard homepage --> increase of loading speed
const Login = React.lazy(() => import("./login"));
const Account = React.lazy(() => import("./account"));
const MonTerritoireVueEnsemble = React.lazy(() => import("./mon-territoire-vue-ensemble"));
const MonTerritoireAnalyseDetaillee = React.lazy(() => import("./mon-territoire-analyse-detaillee"));
const Pap = React.lazy(() => import("./pap"));
const Boitages = React.lazy(() => import("./boitages"));
const PlansActions = React.lazy(() => import("./plans-actions"));
const Resultats = React.lazy(() => import("./resultats"));
const SaisieResultats = React.lazy(() => import ("./resultats-saisie"));
const Courriers = React.lazy(() => import("./courriers"));
const Procurations = React.lazy(() => import("./procurations"));
const Mandants = React.lazy(() => import("./procuration/mandants"));
const Mandataires = React.lazy(() => import("./procuration/mandataires"));
const Mandant = React.lazy(() => import("./procuration/mandant"));
const Mandataire = React.lazy(() => import("./procuration/mandataire"));
const ProcurationsSettings = React.lazy(() => import("./procuration/parametres"));
const Error = React.lazy(() => import("./error"));
const NotFound = React.lazy(() => import("./not-found"));


export default function Webpages (){
    return(
        <AuthProvider>
            <Routes>
                <Route exact path="/" element={
                    <RequireAuth>
                        <Home />
                    </RequireAuth>} />
                <Route path="/login" element={
                    <React.Suspense fallback={<>...</>}>
                        <Login />
                    </React.Suspense>}
                />
                <Route path="/account" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Account />
                        </RequireAuth>
                    </React.Suspense>} />
                <Route path="/mon-territoire" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <MonTerritoireVueEnsemble />
                        </RequireAuth>
                    </React.Suspense>} />
                <Route path="/mon-territoire/analyse-detaillee" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <MonTerritoireAnalyseDetaillee />
                        </RequireAuth>
                    </React.Suspense>} />
                <Route path="/porte-a-porte" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Pap />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/boitages" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Boitages />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/plans-actions" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <PlansActions />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/resultats" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Resultats />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/resultats/saisie" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <SaisieResultats />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/courriers" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Courriers />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/procurations" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Procurations />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/procurations/mandants" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Mandants />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/procurations/mandataires" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Mandataires />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/procurations/mandants/:id" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Mandant />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/procurations/mandataires/:id" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <Mandataire />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/procurations/parametres" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                            <ProcurationsSettings />
                        </RequireAuth>
                    </React.Suspense>} 
                />
                <Route path="/error" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                           <Error /> 
                        </RequireAuth>
                </React.Suspense>} />
                <Route path="*" element={
                    <React.Suspense fallback={<>...</>}>
                        <RequireAuth>
                           <NotFound /> 
                        </RequireAuth>
                </React.Suspense>} />
            </Routes>
        </AuthProvider>
    )
}

// Route path="*" --> Handling the "no match" case. The "*" will match only when no other routes do.
