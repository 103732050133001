import React from 'react';
import '../css/home.css';
import { Link } from 'react-router-dom';
import Layout from '../layout/layout';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Home(){

  return (
      <Layout>
        <main className="common-bg">
            <div className="Home gx-4 text-center mx-auto">

            <Card className="nav-card shadow">
                <Card.Body>
                  <Row className="px-3 align-items-center card-body-content">
                    <Col className="text-center">
                      <Link to="/mon-territoire" className="homepage-link h5 fw-100 mb-0 text-uppercase">Mon territoire</Link>
                    </Col>
                    <Col className="col-auto homepage-link-icon  text-gray-300">
                      <i className=" fas fa-door-closed fa-4x"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="nav-card shadow">
                <Card.Body>
                  <Row className="px-3 align-items-center card-body-content">
                    <Col className="text-center">
                      <Link to="/porte-a-porte" className="homepage-link h5 fw-100 mb-0 text-uppercase">Organiser un porte-à-porte / boitage</Link>
                    </Col>
                    <Col className="col-auto homepage-link-icon  text-gray-300">
                      <i className=" fas fa-door-closed fa-4x"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              
              <Card className="nav-card shadow">
                <Card.Body>
                  <Row className="px-3 align-items-center card-body-content">
                    <Col className="text-center">
                      <Link to="/plans-actions" className="homepage-link h5 mb-0 text-uppercase">Mes plans d'actions</Link>
                    </Col>
                    <Col className="homepage-link-icon col-auto text-gray-300">
                      <i className="fas fa-print fa-4x"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="nav-card shadow">
                <Card.Body>
                  <Row className="px-3 align-items-center card-body-content">
                    <Col className="text-center">
                      <Link to="/resultats" className="homepage-link h5 mb-0 text-uppercase">Remontée des résultats</Link>
                    </Col>
                    <Col className="homepage-link-icon col-auto text-gray-300">
                      <i className="fas fa-chart-line fa-4x"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="nav-card shadow">
                <Card.Body>
                  <Row className="px-3 align-items-center card-body-content">
                    <Col className="text-center">
                      <Link to="/courriers" className="homepage-link h5 mb-0 text-uppercase">Envoyer un courrier ciblé</Link>
                    </Col>
                    <Col className="homepage-link-icon col-auto text-gray-300">
                      <i className="fas fa-envelope fa-4x"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="nav-card shadow">
                <Card.Body>
                  <Row className="px-3 align-items-center card-body-content">
                    <Col className="text-center">
                      <Link to="/procurations" className="homepage-link h5 mb-0 text-uppercase">Procurations</Link>
                    </Col>
                    <Col className="homepage-link-icon col-auto text-gray-300">
                      <i className="fas fa-file-alt fa-4x"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
        </main>
      </Layout> 
  )
}
