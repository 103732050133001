import * as React from "react";
import { Navigate, useLocation } from 'react-router-dom';

const authContext = React.createContext();

export function RequireAuth({ children }) {
  const { getToken, getUserLoginData } = useAuth();
  const location = useLocation();

  const token = getToken();
  const userData = getUserLoginData();

  return (
    token && (userData.expires > Math.floor(new Date().getTime()/1000))
    ) 
    ? children 
    : <Navigate
        to="/login"
        replace
        state={{ path: location.pathname }}
      />;
}

function useAuth() {
  const [authed, setAuthed] = React.useState(false);
 

  return {
    authed,
    login(payload) {
       return fetch(process.env.REACT_APP_API_ROOT + 'auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      .then(data => data.json())
    },
    logout() {
        setAuthed(false);
        localStorage.removeItem('token');
    },
    authenticated(token) {
      setAuthed(true)
      localStorage.setItem('token', token)
    },
    getToken(){
      return localStorage.getItem('token');
    },
    userLoginData(data) {
      localStorage.setItem('userLoginData', JSON.stringify(data));
    }, // stockage des données user reçu dans l'API dans le local storage (besoin de transformer l'objet data en string pour y arriver)
    getUserLoginData(){
      let userLoginDataJSON = localStorage.getItem('userLoginData');
      let userLoginData = userLoginDataJSON && JSON.parse(userLoginDataJSON);
      return userLoginData
    } // fonction pour récuperer userLoginData du localstorage, retransformation en objet (JSON.parse, la première partie permet de tester si userLoginDataJSON est non nul avant d'appliquer JSON.parse) puis retourner l'objet recupéré
  };

}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
