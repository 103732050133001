import React from 'react';
import Header from './header';
import Navigation from './navigation';

const Layout = ({children}) => {
    return (
        <div className="layout">
            <Header />
            <Navigation />
            {children}
        </div>
    )
}

export default Layout;